@use "sass:math";
@use "../_variable" as *;

.archive {
  min-height: 100vh;
  background: url('../../image/home/bg_home.jpg') no-repeat center / cover fixed;

  &::after {
    display: table;
    content: '';
  }

  footer {
    margin-top: rem(27);
  }
}
.header {
  padding: 0 rem(20);
}

// 問題選択画面へボタン
.return {
  position: absolute;
  top: rem(18);
  left: rem(17);
  width: rem(72);
  height: rem(31);
  background: url(../../image/archive/btn_back.svg) left top no-repeat;
  filter: drop-shadow(0 rem(2) rem(1) $c_light_shadow);
  border: none;
  outline: none;
  appearance: none;

  p {
    position: absolute;
    top: rem(5);
    left: rem(18);
    font-family: $f_hd;
    font-size: rem(14);
    font-weight: $fw_ex;
    color: $c_lightness;
    white-space: nowrap;
  }
}

.title {
  @include rect((math.div(142, 375) * 100vw), (math.div(49.19, 375) * 100vw));

  padding-top: rem(22);
  margin: auto;
  text-align: center;
}

.body {
  position: relative;
  z-index: 1;
  padding: rem(26) rem(7 + 15) rem(32);
  margin: rem(19) rem(14) 0;
  background: #f0a937 linear-gradient(#dff568, #f0a937);
  border-radius: rem(20);

  &::before {
    position: absolute;
    top: rem(8);
    right: rem(8);
    bottom: rem(8);
    left: rem(8);
    z-index: 1;
    pointer-events: none;
    content: '';
    border-radius: rem(16);
    box-shadow: #06014686 0 rem(3) rem(20) 0;
    opacity: 0.75;
  }
}

.info {
  @include rect((math.div(197, 375) * 100vw), (math.div(17.05, 375) * 100vw));

  margin: auto;
}

.charaRedPosition {
  top: rem(-1);
  left: rem(-8);
  z-index: 2;
}

.charaRed {
  height: rem(64);
  filter: drop-shadow(rem(-3) rem(3) $c_shadow);
  transform: scale(-1, 1) rotate(-13deg);
}

.charaBluePosition {
  right: rem(-6);
  bottom: rem(-40);
  z-index: 2;
  animation-delay: 1s;
}

.charaBlue {
  height: rem(62);
  filter: drop-shadow(rem(-3) rem(3) $c_shadow);
  transform: scale(-1, 1) rotate(4deg);
}

// 一覧
.list {
  display: flex;
  flex-direction: column;
  margin-top: rem(19);
}
.item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: rem(13) rem(17) rem(9);
  margin: 1rem;
  margin: 0;
  font-family: $f_hd;
  background: $c_archive_list_bg;
  border: rem(3) $c_lightness solid;
  border-radius: rem(16);
  box-shadow: 0 rem(4) rem(4) $c_shadow;

  ~ .item {
    margin-top: rem(12);
  }
}

// NEWバッジ
.new {
  position: absolute;
  top: rem(-5);
  right: rem(2);
}

// 曲名
.music {
  max-width: 100%;
  overflow: hidden;
  font-family: $f_hd;
  font-size: rem(16);
  font-weight: $fw_ex;
  color: $c_darkness;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detail {
  display: flex;
  align-items: center;
}

// 難易度
.level {
  height: rem(14);
}

// 追加日
.update {
  display: block;
  margin-top: rem(2);
  margin-left: rem(11);
  font-size: rem(12);
  font-weight: $fw_m;
  color: $c_archive_text;
  white-space: nowrap;
}

// 該当なしの場合
.empty {
  margin: 1rem;
  font-family: $f_hd;
  font-weight: $fw_m;
  text-align: center;
}
