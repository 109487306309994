@use "../_variable" as *;

.clock {
  @include rect(#{rem(60 + 4 + 4)});

  position: absolute;
  top: rem(34);
  left: rem(10);
  padding: rem(6);
  background: linear-gradient(to bottom right, #d3f6ff, #37b2ce);
  border-radius: 50%;
  box-shadow: 0 rem(2) rem(2) 0 $c_shadow;
  transition: opacity $t_ease, transform $t_ease;
  transform-origin: center;

  &::before {
    position: absolute;
    top: rem(6);
    right: rem(6);
    bottom: rem(6);
    left: rem(6);
    display: block;
    content: '';
    background: linear-gradient(#69bfff, #0389eb);
    border-radius: 50%;
  }

  &.dangerous::before {
    background: linear-gradient(#ffa19a, #ff6991);
  }

  // 正解のメロディを確かめよう
  [data-step='0'] &,
  [data-step='1'] & {
    opacity: 0;
    transform: scale(1.2);
  }
  [data-step='2'] &,
  [data-step='5'] &,
  [data-step='6'] & {
    z-index: 1;
  }
}

.circle {
  z-index: 1;
  transform: rotate(-90deg);
}

.scale {
  fill: #fffcad;
}

.scale_s {
  fill: #8dd8ea;
}

.gauge {
  @include rect(#{rem(60)});

  fill: none;
  stroke: #164e7e;
  stroke-width: rem(30);

  .dangerous & {
    stroke: #9a0029;
  }
}

.count {
  @include fit;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $f_deco;
  font-size: rem(25);
  font-weight: $fw_ex;
  color: #fff;
  text-align: center;
  text-shadow: rem(2) rem(2) 0 #00000070;
}
