@use "../_variable" as *;

.note {
  @include rect(#{rem(67)}, #{rem(93)});

  position: relative;
  display: block;
  overflow: visible;
  pointer-events: auto;
  cursor: pointer;
  user-select: none;

  &::before,
  &::after {
    @include fit;

    display: block;
    pointer-events: none;
    content: '';
  }

  &::before {
    opacity: 0;
    transition: opacity $t_ease;
  }

  &[aria-current] {
    &::before {
      opacity: 1;
    }
  }
  @each $str in [ a, b, c, d, e ] {
    &[data-label='#{$str}']::after {
      background: url(../../image/play/note/label/#{$str}.png) no-repeat center bottom / cover;
    }
  }

  @each $key in [ 'red', 'yellow', 'green', 'blue', 'purple' ] {
    &.note-#{$key} {
      background: url(../../image/play/note/#{$key}.png) no-repeat center bottom / cover;

      &::before {
        background: url(../../image/play/note/current/#{$key}.png) no-repeat center bottom / cover;
      }
    }
  }
}
