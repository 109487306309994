@use "../_variable" as *;

.guide {
  width: 100%;
  padding: rem(20) 0 rem(4);
  margin: 0 auto;
  font-family: $f_hd;
  background-color: $c_bg;
}

.title {
  margin: 0 auto rem(16);
}

.content_text {
  width: 90%;
  margin: 0 auto;
  text-align: center;

  p.intro {
    display: inline-block;
    margin: 0 0 rem(10);
    font-size: rem(14);
    font-weight: $fw_ex;
    color: $c_dark;
    text-align: center;
  }

  p.text {
    display: inline-block;
    margin: 0 0 rem(10);
    font-size: rem(13);
    font-weight: $fw_b;
    color: $c_dark;
    text-align: left;
  }

  .sub_text {
    display: inline-block;
    margin: 0 0 rem(10);
    font-size: rem(13);
    color: $c_dark;
    text-align: left;
  }
}

.list {
  padding-left: 0;
}

.item {
  margin-bottom: rem(40);
  list-style-type: none;

  .order {
    margin-right: rem(2);
  }
}

.title_process {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: rem(8);
  margin-left: vw(10);
  font-size: calc(min(#{vw(19)}, #{rem(19)}));
  font-weight: $fw_ex;
  line-height: 1.3;
  color: $c_accent;
  list-style-type: none;
}

.visual {
  width: 73%;
  margin: 0 auto rem(13);
}

.caution {
  font-size: rem(13);
  text-align: center;
}
