@use '../function/rem' as *;
@use '../function/rem' as *;
@use '../color' as *;
@use '../font' as *;

@mixin tmp_button($w: null, $h: rem(48)) {
  width: $w;
  height: $h;
  padding: 0;
  font-family: $f_hd;
  font-size: rem(20);
  font-weight: $fw_ex;
  color: $c_lightness;
  background: $c_btn_blue no-repeat;
  border: rem(3) $c_lightness solid;
  border-radius: 99rem;
  outline: none;
  box-shadow: 0 rem(2) rem(3) 0 $c_shadow;
  appearance: none;

  img {
    display: inline-flex;
    vertical-align: middle;
  }
}
