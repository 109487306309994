@use "sass:math";
@use "../_variable" as *;

.article {
  position: relative;
  margin: rem(30) auto 0;
  border-radius: rem(33);

  &::before {
    position: absolute;
    top: rem(8);
    right: rem(8);
    bottom: rem(8);
    left: rem(8);
    z-index: 1;
    pointer-events: none;
    content: '';
    border-radius: rem(19);
    box-shadow: #06014686 0 rem(3) rem(20) 0;
  }

  header {
    position: relative;
    z-index: 2;
    padding: 0;
    small {
      @include rect(#{rem(math.div(166, 2))});

      position: absolute;
      top: rem(-1 * (16 + 22 + 8));
      left: rem(-1 * (4 + 22 + 8));
    }

    h2 {
      text-align: center;

      img {
        display: inline-flex;
      }
    }
  }
}

.list {
  position: relative;
  z-index: 2;
  display: flex;
  padding: 0;
  margin: 0;
}
.question {
  position: relative;
  width: 100%;
  list-style: none;

  ~ .question {
    margin-left: rem(10);
  }

  button {
    @include fit;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $c_lightness;
    border: none;
    border: rem(3) solid $c_lightness;
    border-radius: rem(18);
    outline: none;
    box-shadow: $c_shadow 0 rem(4) rem(4) 0;
    appearance: none;
  }
}

// 練習問題
.warmup {
  padding: rem(22) rem(7 + 12) rem(7 + 16);
  margin-bottom: rem(98 + 10); // キャラクターのスペース
  background: #f0a937 linear-gradient(#dff568, #f0a937);
  border-radius: rem(27);

  &::before {
    opacity: 0.75;
  }

  header {
    p {
      margin: rem(-6) 0 rem(10);
      font-family: $f_hd;
      font-size: rem(12);
      font-weight: $fw_m;
    }
  }

  .question {
    height: rem(66);

    button {
      flex-direction: column;
      height: rem(66);
      font-family: $f_hd;
      font-size: rem(14);
      font-weight: $fw_b;
      color: $c_darkness;
      background: url(../../image/home/gloss_t.svg) no-repeat top rem(2) left rem(2),
        url(../../image/home/gloss_b.svg) no-repeat bottom rem(3) right rem(3),
        linear-gradient(#8df6ff, #75e1e5, #24bed6);

      span {
        @include rect(#{rem(22 + 22 + 22 - 3)}, #{rem(19)});

        position: relative;
        display: block;
        margin: rem(3) auto;
        overflow: hidden;
        background: url(../../image/home/warmup/star_disable.svg) repeat-x left top;

        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: block;
          content: '';
          background: url(../../image/home/warmup/star_current.svg) repeat-x left top;
        }
        &[data-level='1']::after {
          width: rem(22 * 1);
        }
        &[data-level='2']::after {
          width: rem(22 * 2);
        }
        &[data-level='3']::after {
          width: rem(22 * 3);
        }
      }
    }
  }

  .charaBlueFooterPosition {
    top: rem(-22);
    right: rem(12);
    z-index: 2;
    animation-delay: 3s;
  }

  .charaBlueFooter {
    height: rem(68);
    filter: drop-shadow(rem(-3) rem(3) $c_shadow);
    transform: scale(-1, 1);
  }

  .charaRedFooterPosition {
    top: rem(12);
    left: rem(20);
    animation-delay: 4s;
  }

  .charaRedFooter {
    height: rem(70);
    filter: drop-shadow(rem(3) rem(3) $c_shadow);
    transform: rotate(-15deg);
  }

  // 枠外のボタンとキャラクター
  footer {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding: 0 rem(19);
  }

  // 練習問題一覧へのボタン
  .archive {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(50);
    font-family: $f_hd;
    font-size: rem(18);
    font-weight: $fw_b;
    color: $c_darkness;
    background: url(../../image/home/gloss_t.svg) no-repeat top rem(2) left rem(3),
      url(../../image/home/gloss_b.svg) no-repeat bottom rem(3) right rem(3), $c_btn_lightblue;
    border: none;
    border: rem(3) solid $c_lightness;
    border-radius: rem(18);
    outline: none;
    box-shadow: $c_shadow 0 rem(4) rem(4) 0;
    appearance: none;
    img {
      margin: auto;
    }
  }
}

// チャレンジ問題
.challenge {
  padding: rem(22);
  background: #63ddfa linear-gradient(#ff72e9, #63ddfa);
  border-radius: rem(27);

  header {
    h2 {
      padding: 0 0 0 rem(24);
      margin: rem(-4) 0 rem(12);
    }
    small {
      top: rem(-45 + 4);
    }
  }

  .question {
    height: rem(96);

    &::after {
      @include rect(#{rem(86)}, #{rem(24)});

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      margin: 0 auto;
      content: '';
      transform: translateY(rem(-12));
    }

    button {
      background: url(../../image/home/gloss_t.svg) no-repeat top rem(2) left rem(2),
        url(../../image/home/gloss_b.svg) no-repeat bottom rem(3) right rem(3),
        linear-gradient(#f6f8af, #f8ea30);

      &::before,
      &::after {
        @include fit;

        content: '';
        border-radius: rem(16);
      }

      span {
        @include rect(100%, 1em);

        display: block;

        &::after {
          position: absolute;
          right: 0;
          bottom: rem(2);
          left: 0;
          z-index: 2;
          margin: auto auto 0;
          font-family: $f_hd;
          font-size: rem(12);
          font-weight: $fw_ex;
        }
      }
    }

    // 出題名
    &:nth-child(1)::after {
      background: url(../../image/home/challenge/txt_series1.svg);
    }

    &:nth-child(2)::after {
      background: url(../../image/home/challenge/txt_series2.svg) no-repeat center / contain;
    }

    // 挑戦前
    &:not([data-correct]) {
      button::after {
        background: url(../../image/home/present.svg) no-repeat center bottom rem(21);
      }
      span::after {
        color: $c_darkness;
        content: attr(data-release);
      }
    }

    // 正解不正解
    &[data-correct] {
      button::before {
        background-color: $c_shadow;
        box-shadow: inset 0 0 rem(90) 0 rgba($c_darkness, 0.8);
      }
      span::after {
        color: $c_lightness;
        content: attr(data-correct);
      }
    }

    &[data-correct='true'] button::after {
      background: url(../../image/home/challenge/correct.png) no-repeat center center;
      background-size: rem(139) rem(90);
    }
    &[data-correct='false'] button::after {
      background: url(../../image/home/challenge/failed.png) no-repeat center center;
      background-size: rem(139) rem(90);
    }
  }
}
