@use "sass:math";
@use "../_variable" as *;

@forward './home-gift';
@forward './home-navi';
@forward './home-article';

.home {
  min-height: 100%;
  text-align: center;
  background: url('../../image/home/bg_top.png') no-repeat center top / 100% auto,
    url('../../image/home/bg_bottom.png') no-repeat center bottom / 100% auto,
    url('../../image/home/bg_home.jpg') no-repeat center / cover fixed;

  > header {
    padding: rem(14) 0 0;

    .charaBlueHeaderPosition {
      top: rem(12);
      left: rem(32);
      z-index: 1;
    }

    .charaBlueHeader {
      height: rem(62);
      filter: drop-shadow(rem(3) rem(3) $c_shadow);
      transform: rotate(-14deg);
    }

    .charaRedHeaderPosition {
      top: rem(40);
      right: rem(26);
      z-index: 2;
      animation-delay: 1s;
    }

    .charaRedHeader {
      height: rem(62);
      filter: drop-shadow(rem(3) rem(3) $c_shadow);
      transform: rotate(-7deg);
    }

    // title logo
    h1 {
      position: relative;
      z-index: 1;
      padding: 0;
      margin: 0;

      img {
        @include rect((math.div(210, 375) * 100vw), (math.div(91.5, 375) * 100vw));

        display: block;
        margin: 0 auto;
      }
    }

    p {
      margin: rem(10) 0 0;
      img {
        @include rect((math.div(323.14, 375) * 100vw), (math.div(20.86, 375) * 100vw));

        display: block;
        margin: 0 auto;
      }
    }
  }
}

.homeBody {
  padding: 0;
  margin: 0 rem(16);

  // 子要素のmarginぶん親の背景も広げる
  &::after {
    display: table;
    content: '';
  }
}
