@use 'modern-css-reset';
@use "_variable" as *;

/* stylelint-disable */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;800&family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap&text=ABCDE→難易度結果は更新されませんてになった音を正しいメロディに並べ替えよう！入い符重ねて、指を離すとびることができるはから確認。（聴間タイマーカウント）');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400&display=swap&text=※Wi-Fi環境下でのご利用を推奨いたします。音が出ない場合、ご利用端末の音量やサイレント設定を見直してみてください。');
/* stylelint-enable */

body {
  min-height: 100%;
  margin: 0;
  font-family: 'Hiragino Kaku Gothic ProN', -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  font-size: vw(16);
  color: $c_dark;
  user-select: none;
  background-color: $c_lightness;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;

  &.isFixedScroll {
    height: 100%;
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.l-body {
  position: relative;
  z-index: 1;
}

button img {
  pointer-events: none;
}

.isChangeUrl {
  animation: zoom 800ms;
}
