@use "sass:math";
@use "../_variable" as *;

.result {
  min-height: 100vh;
  background: url('../../image/play/bg_play.jpg') no-repeat center / cover fixed;
}

.body {
  position: relative;
  width: 100vw;
  height: rem(450);
  padding-top: rem(44);
  margin: 0 auto;
  overflow: hidden;
}

.comment {
  position: absolute;
  right: 0;
  bottom: rem(318);
  left: 0;
  width: rem(375);
  margin: 0 auto;
}

// 正解したとき
.correctedResult {
  position: relative;
  height: rem(84 + 222 + 77.5);
  margin: rem(108) 0 rem(-60);
  font-family: $f_hd;
  font-weight: $fw_ex;
  text-align: center;
}

// 結果の背景の円
.circle {
  @include rect(rem(222));

  position: relative;
  margin: rem(16) auto;
  border-radius: 50%;

  // Excellent!
  .rank4 & {
    background: #f9ee66 url('../../image/result/circle_4.png') no-repeat center / cover;
    box-shadow: 0 0 rem(10) 0 rgba(black, 0.16), 0 rem(42) rem(118) rem(0) #e2eb00;

    &::after {
      @include rect(rem(80), rem(60));

      position: absolute;
      top: rem(-48);
      right: 0;
      left: 0;
      display: block;
      margin: 0 auto;
      pointer-events: none;
      content: '';
      background: url('../../image/result/crown_4.png') no-repeat center / contain;
    }
  }

  // Great
  .rank3 & {
    background: #f8f8f8 url('../../image/result/circle_3.png') no-repeat center / cover;
    box-shadow: 0 0 rem(10) 0 rgba(black, 0.16), 0 rem(42) rem(118) rem(0) #a4dbe0;

    &::after {
      @include rect(rem(math.div(159, 2)), rem(math.div(119, 2)));

      position: absolute;
      top: rem(-48);
      right: 0;
      left: 0;
      display: block;
      margin: 0 auto;
      pointer-events: none;
      content: '';
      background: url('../../image/result/crown_3.png') no-repeat center / contain;
    }
  }

  // Good
  .rank2 & {
    background: #ffe5fc url('../../image/result/circle_2.png') no-repeat center / cover;
    box-shadow: 0 0 rem(10) 0 rgba(black, 0.16), 0 rem(42) rem(118) rem(0) #fff;
  }

  // ギリギリ
  .rank1 & {
    background: #dbf2fd url('../../image/result/circle_1.png') no-repeat center / cover;
    box-shadow: 0 0 rem(10) 0 rgba(black, 0.16), 0 rem(42) rem(118) rem(0) #fff;
  }
}

.label {
  padding-top: rem(64);
  margin-bottom: rem(-4);
  font-size: rem(17);
}
.time {
  font-size: rem(59);
}
.unit {
  font-size: rem(27);
}

// 称号
.rank {
  position: relative;
  z-index: 1;
  max-width: rem(375);
  max-height: rem(40);
  margin: 0 auto 0;
}

// 不正解のとき
.failedResult {
  position: relative;
  margin-top: rem(64);
  &::before {
    @include rect(calc(100vw - #{rem(9 + 25 + 9 + 25)}), rem(240));

    position: absolute;
    top: rem(69);
    right: 0;
    left: 0;
    display: block;
    height: rem(240);
    margin: 0 auto;
    content: '';
    background-color: $c_lightness;
    border-radius: rem(27);
    box-shadow: 0 0 0 rem(9) rgba($c_lightness, 0.48), 0 0 rem(3) rem(6) rgba(#0244c1, 0.16);
  }

  &::after {
    @include rect(rem(201), rem(133));

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: block;
    margin: 0 auto;
    pointer-events: none;
    content: '';
    background: url('../../image/bg_round.svg') no-repeat center / cover;
    transform: translateY(rem(-38));
  }
}
.data {
  position: relative;
  z-index: 2;
  height: rem(240);
  padding-top: rem(30);
  margin: rem(9) rem(9 + 25) rem(9);
  font-family: $f_hd;
  font-size: rem(27);
  font-weight: $fw_b;
  text-align: center;
  letter-spacing: 0.1em;
  transform: translateY(rem(69));
}
.orderTitle {
  margin: 0 auto rem(10);
}
.order {
  margin-bottom: rem(20);
}
.arrow {
  color: $c_sub;
}

// キャラクター
.charaBlue {
  @include rect(rem(80), rem(75.79));
}
.charaBluePosition {
  position: absolute;
  top: rem(108);
  right: rem(22);
  filter: drop-shadow(rem(3) rem(6) $c_shadow);
  animation-duration: 5.75s;
  animation-delay: 0.2s;
}
.charaRed {
  @include rect(rem(89), rem(79));

  transform: rotate(-5deg);
}
.charaRedPosition {
  position: absolute;
  top: rem(106);
  left: rem(14);
  filter: drop-shadow(rem(3) rem(6) $c_shadow);
  animation-duration: 6s;
  animation-delay: 0.7s;
}
.charaRedPosition_low {
  position: absolute;
  top: rem(-42);
  right: 0;
  left: 0;
  display: block;
  width: rem(90);
  margin: 0 auto;
  filter: drop-shadow(rem(3) rem(6) $c_shadow);
}
.charaBluePosition_failed {
  position: absolute;
  top: rem((66 + 69) * -1);
  right: 0;
  left: 0;
  display: block;
  width: rem(76);
  margin: rem(69) auto 0;
  filter: drop-shadow(rem(3) rem(6) $c_shadow);
}

// キラキラ
.blink {
  @include rect(rem(36), rem(54));

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    animation: blink infinite;
  }

  &::after {
    @include fit;
    @include rect(50%, 33.33%);

    display: block;
    margin: auto;
    content: '';
    background: radial-gradient(rgba($c_lightness, 0.75) 33%, rgba($c_lightness, 0.25) 66%);
    transform: rotate(45deg);
    animation: blink_circle infinite;
  }
}
.blinkA {
  top: rem(-42);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(-72));

  img,
  &::after {
    animation-duration: 2.3s;
    animation-delay: 0.7s;
  }
}
.blinkB {
  top: rem(-32);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(72));

  img,
  &::after {
    animation-duration: 1.7s;
    animation-delay: 0s;
  }
}
.blinkC {
  top: rem(-12);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(-54)) scale(0.75);
  img,
  &::after {
    animation-duration: 2.7s;
    animation-delay: 1.4s;
  }
}
.blinkD {
  top: rem(-6);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(38)) scale(0.5);
  img,
  &::after {
    animation-duration: 2.1s;
    animation-delay: 1.9s;
  }
}

// ヨレヨレ
.failed_note {
  @include rect(rem(41.604), rem(40.464));

  position: absolute;
  display: block;
  transform: translateY(rem(-6)) scale3d(1, 1, 1);
  animation: drop 2.25s forwards;
}
.noteA {
  top: rem(40 - 69);
  right: rem(144);
  left: 0;
  margin: 0 auto;
  animation-delay: 1s;
  img {
    transform: rotate(108deg) scale(0.9);
  }
}
.noteB {
  top: rem(9 - 69);
  right: 0;
  left: rem(126);
  margin: 0 auto;
  animation-delay: 1.25s;
}

// footer
.footer {
  width: 100vw;
  padding: 0 rem(24);
  padding-bottom: rem(90);
  margin: 0 auto;
}

.navi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: rem(16);
}

// もう一回あそぶボタン
.btnReplay {
  @include tmp_button(66.6%, auto);

  padding: rem(6) 0;
  background: $c_btn_yellow;
  img {
    @include rect(#{rem(137)}, #{rem(23)});
  }
  span {
    display: block;
    margin-top: rem(-6);
    font-size: rem(10);
    font-weight: $fw_b;
    color: #505050;
    letter-spacing: rem(0.2);
    transform: translateY(rem(3));
  }
}

// シェアボタン
.share {
  @include tmp_button(33.3%, rem(42));

  display: inline-flex;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  margin-left: rem(8);
  font-family: $f_hd;
  font-size: rem(16);
  font-weight: $fw_ex;
  line-height: rem(40);
  color: $c_darkness;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: $c_note_white;

  img {
    @include rect(#{rem(15.34)}, #{rem(12.27)});

    margin: 0 rem(4) 0 rem(-4);
  }
}

// 問題選択画面へボタン
.return {
  @include tmp_button(100%, rem(57));
}
