@use "../_variable" as *;

h1 + button {
  position: absolute;
  top: rem(14);
  right: rem(0);
  width: rem(91) !important;
  padding-left: rem(18) !important;
  text-align: left;
  border-radius: 999em 0 0 999em !important;
  transform-origin: right !important;
}

.guide {
  @include tmp_button(33.3%, rem(42));

  flex-grow: 1;
  font-family: $f_hd;
  font-size: rem(14);
  font-weight: $fw_ex;
  color: $c_darkness;
  white-space: nowrap;
  background: $c_note_white;
  border-width: rem(2);
  border-radius: 999em;
  outline: none;

  img {
    margin: 0 rem(4) 0 rem(-4);
  }
}

// モーダルへ付与する上書きクラス
.guideWrapper[class*='dialog_content'] {
  padding: 0 rem(10);
  background-color: $c_bg;

  p {
    font-weight: $fw_r;
  }

  button[class*='button'] {
    margin-bottom: rem(30);
  }
}
