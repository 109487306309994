@use "../_variable" as *;

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(24);

  &:not(.isOpen) {
    pointer-events: none;
    user-select: none;
  }

  &.isOpen {
    pointer-events: default;
    user-select: auto;
  }
}

.screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $c_screen;

  :not(.isOpen) & {
    opacity: 0;
    transition: opacity $t_ease;
  }

  .isOpen & {
    opacity: 1;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 86vh;
  padding: rem(10);
  color: $c_darkness;
  background-color: $c_lightness;
  border-radius: rem(24);

  :not(.isOpen) & {
    opacity: 0;
  }

  .isOpen & {
    opacity: 1;
    transition: opacity $t_ease;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: rem(40) 0 0;
    font-family: $f_hd;
    font-weight: $fw_ex;
    line-height: 1.5;
    text-align: center;
  }

  .detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 98%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: rem(24);
  }

  .button {
    @include tmp_button(calc(100% - #{rem(56 + 56 - 10)}));

    min-height: rem(48);
    margin: rem(20) 0;
  }

  .close {
    @include tmp_button(#{rem(40)}, #{rem(40)});

    position: absolute;
    top: rem(-10);
    right: rem(-10);
    border: rem(4) $c_lightness solid;
    border-radius: 50%;

    &::before,
    &::after {
      @include fit;
      @include rect(#{rem(18.25)}, #{rem(2.6)});

      display: block;
      margin: auto;
      content: '';
      background: currentColor;
      border-radius: rem(999);
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}
