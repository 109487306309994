@use "../_variable" as *;

.toast {
  position: fixed;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 2;
  padding: rem(16) rem(16) rem(24);
  color: $c_current;
  pointer-events: none;
  user-select: none;
  background-color: $c_lightness;

  &:not(.isActive) {
    transform: translateY(0);
  }

  &.isActive {
    transition: transform $t_ease 250ms;
    transform: translateY(-100%);
  }
}

.message {
  height: rem(28);
  font-family: $f_hd;
  font-weight: $fw_ex;
}
