@use "../_variable" as *;

.filter {
  position: relative;
  z-index: 3;
  display: flex;
  gap: rem(3);
  width: 100%;
  height: vw(34);
  padding: 0 vw(3);
  margin: rem(10) auto 0;
  background: #8c9926;
  border-radius: rem(99);
  box-shadow: 0 rem(1) rem(3) $c_shadow;
}

.item {
  position: relative;
  flex-grow: 1;
  width: 25%;
  input[type='radio'] {
    display: none;
  }
}

.button {
  position: absolute;
  top: vw(3);
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: vw(72);
  height: vw(28);
  padding: rem(9) rem(6);
  margin: 0 auto;
  font-family: $f_hd;
  font-size: rem(14);
  font-weight: $fw_ex;
  color: $c_lightness;
  box-shadow: none;

  img {
    height: rem(15);
  }

  input[type='radio']:checked ~ & {
    color: $c_archive_text;
    background: linear-gradient(#cef5ff, #6cc0f4);
    border-radius: rem(99);
    box-shadow: 0 rem(1) rem(1) $c_light_shadow;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: rem(15);
      margin: auto;
      content: '';
    }
  }
}

.item:nth-child(2) {
  input[type='radio']:checked + .button {
    img {
      display: none;
    }

    &::after {
      background: url(../../image/archive/level_star_01.svg) no-repeat center;
    }
  }
}

.item:nth-child(3) {
  input[type='radio']:checked + .button {
    img {
      display: none;
    }

    &::after {
      background: url(../../image/archive/level_star_02.svg) no-repeat center;
    }
  }
}
