@use "sass:math";
@use "../_variable" as *;
.applyGiftMessage[class*='dialog_content'] {
  margin: auto rem(10);
  background-color: transparent;
  transform: translateY(rem(40));

  &::after {
    @include fit;

    content: '';
    background-color: $c_lightness;
    border-radius: rem(27);
    box-shadow: 0 0 0 rem(9) rgba($c_lightness, 0.48), 0 0 rem(3) rem(6) rgba(#0244c1, 0.16);
  }

  &::before {
    position: absolute;
    top: -50vmin;
    right: -50vmin;
    bottom: -50vmin;
    left: -50vmin;
    display: block;
    content: '';
    background: url(../../image/apply/bg_light.png) no-repeat center / rem(math.div(1154, 2))
      rem(math.div(1154, 2));
    transform-origin: 50% 50%;
    animation: rotate 9s linear infinite;
  }

  figure {
    position: relative;
    z-index: 1;
    margin: rem(5) auto;

    img {
      @include rect(#{rem(290)}, #{rem(123)});
    }
  }
  p {
    position: relative;
    z-index: 1;
    padding: 0;
    margin: 0;
  }

  button[class*='button'] {
    position: relative;
    z-index: 1;
    margin: rem(10) auto rem(5);
    color: $c_darkness;
    background: $c_btn_yellow;
    border-width: rem(2);
  }

  button[class*='close'] {
    z-index: 1;
  }

  .applyGift {
    position: relative;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    margin: 0 auto;
    pointer-events: none;
    transform: translateY(rem(-107));
  }

  .applyTitle {
    position: relative;
    z-index: 1;
    margin: rem(12 + 10) 0 0;

    img {
      width: auto;
      height: auto;
    }
  }
}

// N名様にプレゼント
.giftFigcaption {
  padding-bottom: rem(12);
  font-family: $f_hd;
  font-size: rem(17);
  font-weight: $fw_ex;
  line-height: 1.1;

  .small {
    margin: 0 auto;
    font-size: rem(13);
    font-weight: $fw_m;
    line-height: math.div(18, 13);
  }

  strong {
    font-size: rem(27);
    font-weight: $fw_ex;
    line-height: 1;
    vertical-align: text-bottom;
  }
}

// キラキラ
.blinkPresentA {
  top: rem(-88);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(-84));

  .blinkPresentAimg,
  &::after {
    animation-duration: 2.3s;
    animation-delay: 0.7s;
  }
}
.blinkPresentB {
  top: rem(-72);
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateX(rem(84));

  .blinkPresentBimg,
  &::after {
    animation-duration: 1.7s;
    animation-delay: 0s;
  }
}
