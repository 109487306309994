@use "../_variable" as *;

.button {
  @include rect(#{rem(45)});

  padding: 0;
  color: $c_lightness;
  background: #fff #{$c_note_bg} no-repeat;
  border: none;
  border: 2px solid $c_lightness;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 2px 2px 0 $c_shadow;
  transition: fill $t_ease, opacity $t_ease;

  &.button-red {
    fill: $c_note_red;
  }

  &.button-yellow {
    fill: $c_note_yellow;
  }

  &.button-green {
    fill: $c_note_green;
  }

  &.button-blue {
    fill: $c_note_blue;
  }

  &.button-purple {
    fill: $c_note_purple;
  }

  &:disabled {
    fill: $c_note_disabled !important;
  }
}
