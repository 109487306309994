@use "sass:math";
@use "../_variable" as *;

.play {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  touch-action: manipulation;
  background: url('../../image/play/bg_play.jpg') no-repeat center / cover fixed;
}

.header {
  max-width: 100vw;
}

.charaRedPosition {
  top: rem(172);
  left: rem(28);
  animation-delay: 4s;
}

.charaRed {
  height: rem(73);
  filter: drop-shadow(rem(3) rem(6) $c_shadow);
  transform: rotate(-5deg);
}

.number {
  font-size: rem(41);
  vertical-align: rem(-1);
}

.footer {
  position: relative;
  max-height: calc(100vh - #{rem(185 + 185 + 24)});

  button:last-child {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.finish {
  @include rect(#{rem(204)}, #{rem(200)});

  position: relative;
  display: block;
  margin: auto auto rem(40);
  background: transparent url('../../image/play/btn_complete_on.png') no-repeat center bottom /
    rem(math.div(408, 2)) rem(math.div(343, 2));
  border: 0;
  outline: none;
  appearance: none;

  [data-step='7'] & {
    opacity: 0;
  }

  &::after {
    @include rect(#{rem(math.div(311, 2))}, #{rem(math.div(305, 2))});

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto auto rem(29) auto;
    content: '';
    background: transparent url('../../image/play/btn_complete_off.png') no-repeat center bottom /
      rem(math.div(311, 2)) rem(math.div(305, 2));
    opacity: 1;
    transition: opacity $t_ease;
  }

  // ボタン押下状態
  &[aria-busy='true']::after {
    opacity: 0;
  }
}

.judging {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: rem(24) auto auto;
  opacity: 0;
  transition: opacity $t_ease;

  // 表示させる
  [data-step='7'] & {
    opacity: 1;
  }
}
