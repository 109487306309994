@use "sass:math";
@use "../_variable" as *;

.navi {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem(30);
  margin: rem(18) 0 0;

  .toWarmup {
    @include tmp_button(66.6%, rem(42));

    display: flex;
    flex-grow: 2;
    margin-left: rem(10);
    text-align: center;
    background: $c_btn_lightblue;
    border-width: rem(2);

    img {
      @include rect(#{rem(100.26)}, #{rem(22.63)});

      display: block;
      margin: auto;
      transform: translateX(rem(math.div(22, 2) * 0.5));
    }
  }
}
