@use "../_variable" as *;

.order {
  position: relative;
  margin: 0 0 auto;
}

.list {
  position: relative;
  box-sizing: border-box;
  height: rem(204);
  max-height: calc(100vh - #{rem(185 + 185)});
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: visible;
  touch-action: none;
  user-select: none;

  // 320w
  @media screen and (max-width: 374px) {
    width: rem(320);
    padding: 0 0 0 rem(2);
  }

  // 375w
  @media screen and (min-width: 375px) {
    width: rem(375);
    padding: 0 0 0 rem(15);
  }
  // 100秒以内に正しい順番に並べよう!
  [data-step='2'] & {
    z-index: 1;
    pointer-events: none;
  }
}

.item {
  position: absolute;
  bottom: 0;
  height: rem(93);
  margin-left: 0;
  transform-origin: 50% 100% 0;

  // 320w
  @media screen and (max-width: 374px) {
    width: rem(64);
  }

  // 375w
  @media screen and (min-width: 375px) {
    width: rem(67 + 5);
  }
  // 100秒以内に正しい順番に並べよう!
  [data-step='2'] & {
    pointer-events: none;
  }
}

.piece {
  display: flex;
  justify-content: space-between;
  padding: rem(10) 0;
  margin: 0 auto;
  // 320w
  @media screen and (max-width: 374px) {
    width: calc(100% - #{rem(10 + 10)});
    max-width: rem(320 - 10 - 10);
  }
  // 375w
  @media screen and (min-width: 375px) {
    width: calc(100% - #{rem(20 + 20)});
    max-width: rem(375 - 18 - 18);
  }
}
