@keyframes laughing {
  8% {
    transform: scale(1.005, 1.01);
  }
  10% {
    transform: scale(1, 0.96);
  }
  12% {
    transform: scale(1, 1);
  }
  14% {
    transform: scale(1, 0.97);
  }
  16% {
    transform: scale(1, 1);
  }
  18% {
    transform: scale(1, 0.98);
  }
  20% {
    transform: scale(1, 1);
  }
  22% {
    transform: scale(1, 0.99);
  }
  24% {
    transform: scale(1, 1);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0) scale(1);
  }
  25% {
    opacity: 0.8;
    transform: rotate(90deg) scale(0.8);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  75% {
    opacity: 0.8;
    transform: rotate(270deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

// キラキラ
@keyframes blink {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  6% {
    opacity: 1;
    transform: scale(1);
  }
  8% {
    opacity: 1;
    transform: scale(1);
  }
  32% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
@keyframes blink_circle {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 1;
  }
  6% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    transform: translateY(rem(-6)) scale3d(1, 1, 1);
  }
  70% {
    transform: translateY(rem(0)) scale3d(1, 1, 1);
  }
  75% {
    transform: translateY(rem(3)) scale3d(0.8, 1, 1);
  }
  80% {
    transform: translateY(rem(0)) scale3d(1, 1, 1);
  }
  100% {
    transform: translateY(0) scale3d(1, 1, 1);
  }
}
