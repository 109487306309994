@use "sass:math";
@use "../_variable" as *;

/** pagination
    ページネーション
*/
.pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: vw(170);
  height: rem(33);
  margin: rem(20) auto;
  background: #fff9;
  border: rem(2) solid $c_lightness;
  border-radius: rem(9);
  box-shadow: 0 rem(3) rem(6) #0244c129;
}

.page {
  font-family: $f_hd;
  font-size: rem(20);
  font-weight: $fw_ex;
  color: $c_archive_text;

  span {
    font-size: rem(15);
  }
}

.previous {
  left: rem(-20);
  margin-right: math.div(5, 375) * 100vw;

  img {
    transform: scale(-1, 1);
  }
}

.next {
  right: rem(-20);
  margin-left: math.div(5, 375) * 100vw;
}

.previous,
.next {
  @include rect(#{rem(40)});

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: rem(51);
  padding: 0;
  margin: 0;
  background: $c_btn_blue;
  border: rem(2) solid $c_lightness;
  border-radius: rem(99);
  box-shadow: 0 rem(2) rem(2) $c_light_shadow;

  &:disabled {
    display: none;
  }
}
