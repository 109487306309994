@use "sass:math";
@use "../_variable" as *;

.music {
  position: relative;
  height: rem(132);
  padding: rem(12) rem(28) rem(18);
  margin: rem(10) rem(14) 0;
  font-family: $f_hd;
  line-height: 1.5;
  color: $c_darkness;
  text-align: center;
  background: #fffc;
  border: rem(6) solid $c_lightness;
  border-radius: rem(20);
  box-shadow: #0244c129 0 rem(3) rem(6);

  // 正解のメロディを確かめよう
  [data-step='0'] &,
  [data-step='1'] & {
    z-index: 1;
  }
}

.headline {
  text-align: center;

  img {
    margin: auto;
  }
}

.name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(56);
  margin-top: rem(10);
  font-size: rem(22);
  font-weight: $fw_ex;
  text-align: center;
}

.button {
  @include rect(#{rem(37)});

  position: absolute;
  top: 100%;
  right: rem(-6);
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
  background: #2990dd linear-gradient(#24baf2, #2990dd);
  border: rem(4) solid $c_lightness;
  border-radius: 50%;
  outline: none;
  transition: opacity $t_ease;
  transform: translateY(rem(-1 * (math.div(45, 2) - 6 - 4)));

  &:disabled {
    background: #868686 linear-gradient(#b7b7b7, #777);
  }

  span {
    @include rect(#{rem(58)}, #{rem(25)});

    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    transform: translate(-50%, rem(10));
  }

  // 正解のメロディを確かめよう
  [data-step='0'] &,
  [data-step='1'] & {
    opacity: 0;
  }
}
