$c_darkness: #000;
$c_dark: #000;
$c_lightness: #fff;
$c_light: #fff;
$c_sub: #b5b5b5;

$c_current: #127fc7;
$c_accent: #e339b5;
$c_bg: #e5f7ff;

$c_shadow: #00000030;
$c_light_shadow: #272e2f40;
$c_screen: #0009;

$c_note_red: #fb2397;
$c_note_yellow: #eabb12;
$c_note_green: #2fc647;
$c_note_blue: #31a9ef;
$c_note_purple: #c153fb;
$c_note_disabled: #b4b4b4;

$c_note_bg: linear-gradient(#fff, #dcecf1);

$c_btn_blue: linear-gradient(#5fe6ff, #0071c3);
$c_btn_lightblue: linear-gradient(#a5f5ff, #08b5eb);
$c_btn_yellow: linear-gradient(#fefa56, #ebb500);
$c_note_white: linear-gradient(#fff, #dcecf1);

$c_archive_list_bg: linear-gradient(#fff, #e5f7ff);
$c_archive_text: #024c83;
