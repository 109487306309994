@use "sass:math";
@use "../_variable" as *;

.gameStep {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(30);

  &:not(.isOpen) {
    pointer-events: none;
    user-select: none;
  }

  &.isOpen {
    pointer-events: default;
    user-select: auto;
  }
}

.screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $c_screen;

  :not(.isOpen) & {
    opacity: 0;
    transition: opacity $t_ease;
  }

  .isOpen & {
    opacity: 1;
  }
  // プレイ中
  [data-step='4'] & {
    pointer-events: none;
  }
}

.text {
  position: fixed;
  top: calc(100vh - #{rem(185 + 185)});
  right: 0;
  bottom: rem(48);
  left: 0;
  z-index: 1;
  display: block;
  height: 3em;
  margin: 0 auto;
  text-align: center;
  @media screen and (min-height: 574px) {
    top: rem(200 + 9);
  }
  // プレイ中
  [data-step='4'] & {
    pointer-events: none;
  }

  p {
    font-family: $f_hd;
    font-size: rem(22);
    font-weight: $fw_ex;
    color: $c_lightness;
    text-align: center;

    // 正解のメロディを確かめよう
    [data-step='0'] &,
    [data-step='1'] & {
      margin: rem(36) 0;
    }

    // 100秒以内に正しい順番に並べよう!
    [data-step='2'] & {
      margin: rem(12) 0 0;
    }
  }

  :not(.isOpen) & {
    opacity: 0;
  }

  .isOpen & {
    opacity: 1;
    transition: opacity $t_ease;
  }
}

.play {
  @include rect(#{rem(90)});

  position: relative;
  padding: 0;
  margin: 0 0 rem(32);
  cursor: pointer;
  background: #2990dd linear-gradient(#24baf2, #2990dd);
  border: rem(7) solid $c_lightness;
  border-radius: 50%;
  outline: none;
  appearance: none;

  &:disabled {
    background: $c_sub;

    svg path {
      fill: $c_lightness !important;
    }
  }
}

.skip {
  @include rect(#{rem(179)}, #{rem(45)});

  display: block;
  padding: 0;
  margin: 0 auto;
  font-family: $f_hd;
  font-size: rem(20);
  font-weight: $fw_ex;
  color: $c_lightness;
  cursor: pointer;
  background-color: rgba($c_lightness, 0.48);
  border: none;
  border-radius: 999em;
  outline: none;
  appearance: none;
  transition: opacity $t_ease;

  &:disabled {
    opacity: 0;
  }
}

.button {
  @include tmp_button;

  width: rem(260);
  margin: rem(132) 0 0;

  &:disabled {
    background: transparent;
    border: 0;
    box-shadow: none;
  }
}

.finished_button {
  @include tmp_button;

  width: rem(260);
  margin: rem(150) 0 0;
}

.start {
  @include rect(#{rem(math.div(515, 2))}, #{rem(math.div(146, 2))});
  @include fit;

  margin: rem(90) auto 0;
}

.finish {
  @include rect(#{rem(math.div(665, 2))}, #{rem(math.div(155, 2))});
  @include fit;

  margin: rem(40) auto 0;
}
.timeout {
  @include rect(#{rem(math.div(621, 2))}, #{rem(math.div(162, 2))});
  @include fit;

  margin: rem(40) auto 0;
}

// 正誤
.judging {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;

  &[data-judging] {
    pointer-events: auto;
    opacity: 1;
  }
}
.corrected {
  @include rect(#{rem(math.div(1019, 2))}, #{rem(math.div(999, 2))});

  position: absolute;
  top: 0;
  right: -50vw;
  bottom: 0;
  left: -50vw;
  max-width: auto;
  max-width: initial;
  margin: auto;
  opacity: 0;
  transition: transform 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 250ms ease;
  transform: scale(0.9);
  transform-origin: 50%;

  .judging[data-judging='corrected'] & {
    opacity: 1;
    transform: scale(1);
  }
}

.failed {
  @include rect(#{rem(math.div(814, 2))}, #{rem(math.div(821, 2))});

  position: absolute;
  top: 0;
  right: -50vw;
  bottom: 0;
  left: -50vw;
  max-width: auto;
  max-width: initial;
  margin: auto;
  opacity: 0;
  transition: transform 1000ms ease, opacity 250ms ease;
  transform: scale(1.025);
  transform-origin: 50%;

  .judging[data-judging='failed'] & {
    opacity: 1;
    transform: scale(1);
  }
}
