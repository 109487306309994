@use "sass:math";
@use "../_variable" as *;

// プレゼント応募
.gift {
  position: relative;
  margin: rem(76.8 - 11 + 24) rem(9) rem(40);
  background-color: $c_lightness;
  border-radius: rem(33);
  box-shadow: 0 0 0 rem(9) rgba($c_lightness, 0.5), 0 0 rem(3) rem(6) rgba(#0244c1, 0.16);

  header {
    small {
      @include rect(#{rem(338.03)}, #{rem(76.8)});

      position: absolute;
      bottom: 100%;
      left: 50%;
      display: block;
      padding-top: rem(14);
      font-family: $f_hd;
      font-size: rem(13);
      font-weight: $fw_m;
      line-height: math.div(18, 13);
      color: $c_lightness;
      background: url('../../image/home/bg_giftHeader.svg') no-repeat center / 100% auto;
      transform: translate(-50%, rem(11));
    }

    h2 {
      font-family: $f_hd;
      font-size: rem(18);
      font-weight: $fw_ex;
      line-height: math.div(24, 18);
    }
  }

  figure {
    display: block;
    margin: 0 auto;

    img {
      @include rect(#{rem(290)}, #{rem(123)});

      display: block;
      margin: 0 auto;
    }
  }

  footer {
    padding: 0 0 rem(20);
  }
}

// N名様にプレゼント
.giftFigcaption {
  padding-bottom: rem(12);
  font-family: $f_hd;
  font-size: rem(17);
  font-weight: $fw_ex;
  line-height: 1.1;

  .small {
    margin: 0 auto;
    font-size: rem(13);
    font-weight: $fw_m;
    line-height: math.div(18, 13);
  }

  strong {
    font-size: rem(27);
    font-weight: $fw_ex;
    line-height: 1;
    vertical-align: text-bottom;
  }
}

// 応募キーワード
.gifKeyword {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: rem(4) rem(20) 0;

  dl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(10) rem(16);
    font-family: $f_hd;
    background-color: #faeaff;
    border-radius: rem(12);

    dt {
      font-size: rem(11);
      line-height: 1.5;
      white-space: nowrap;
      user-select: none;
    }

    dd {
      margin-top: rem(3);
      font-size: rem(20);
      font-weight: $fw_ex;
      line-height: 1.2;
      color: $c_accent;
    }
  }

  // コピーしました
  &::before {
    @include fit;

    pointer-events: none;
    content: '';
    background-color: $c_lightness;
    opacity: 0;
    transition: opacity $t_ease;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: inline-block;
    padding: rem(6) rem(6);
    font-size: rem(12);
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    pointer-events: none;
    content: 'コピーしました';
    background: #555;
    border-radius: rem(6);
    opacity: 0;
    transition: opacity $t_ease;
    transform: translate(-50%, -12.5%);
  }
  &[data-copied] {
    &::before {
      opacity: 0.5;
    }
    &::after {
      opacity: 1;
    }
  }
}
// タップするとコピーできるよ
.gifKeywordGuide {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: rem(28);
  padding-left: rem(22 + 12);
  margin: rem(10) auto 0;
  font-size: rem(12);
  line-height: 1.3;
  user-select: none;
  background: url('../../image/home/ico_touch.svg') no-repeat left center;
  background-size: rem(22) rem(28);
}

// 応募ボタン
.gifCTA {
  @include tmp_button;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(18) rem(50) 0;
  text-align: center;
  background: $c_btn_yellow;
  border-width: rem(2);

  figure img {
    @include rect(#{rem(80)}, #{rem(26)});
  }
}

// 応募受付期間
.giftPeriod {
  font-family: $f_hd;
  font-size: rem(13);
  font-weight: $fw_m;
  font-feature-settings: 'palt';

  // ボタンとの余白
  .gifCTA + & {
    margin-top: rem(15);
  }

  strong {
    display: block;
    font-weight: $fw_m;
    color: #f50000;
  }
}
